<template>
	<main class="page-container container">
		<div class="page-index">
			<h3 class="text-left page_index__title">{{ displayLang.title }}</h3>
			<a :href="url + 'contractfiles/volumeofindustrialproduction/' + item" class="volume-item"
				v-for="(item, idx) in volumesData" :key="idx" download>
				{{ "Информация о промышленной продукции по Республике Узбекистан - " + (idx + 1) }}
			</a>
		</div>
	</main>
</template>
<script>
import LanguageService from "../../services/LanguageService";
export default {
	name: "about",
	data() {
		return {
			currentLang: LanguageService.getLanguage(),

			uz: {
				title: "Sanoat ishlab chiqarish hajmi",
			},

			ru: {
				title: "Объем производства промышленной продукции",
			},
			cyrl: {
				title: "Саноат ишлаб чиқариш ҳажми",
			},
			volumesData: [],
		};
	},
	computed: {
		displayLang() {
			switch (this.currentLang) {
				case "ru":
					return this.ru;
				case "cyrl":
					return this.cyrl;
				default:
					return this.uz;
			}
		},
		url() {
			return process.env.VUE_APP_BASE_URL_DOMAIN;
		},
	},
	methods: {
		async getVolumes() {
			try {
				const { data } = await this.$api.get("/api-file/Files/GetAllVolumeOfIndustrialProduction");
				this.volumesData = data.result;
			} catch (error) {
				this.$notification.error(error);
			}
		},
	},
	mounted: function () {
		this.getVolumes();
	},
};
</script>
<style scoped>
.page-container {
	padding-bottom: 20px;
}

.page-index {
	background: white;
	border-radius: 8px;
	box-shadow: 0 1px 10px rgb(0 0 0 / 0.1);
	padding: 40px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.page_index__title {
	font-size: 32px;
}

.volume-item {
	color: #00cfb3;
	text-align: start;
	font-size: 24px;
	width: fit-content;
}

.volume-item:hover {
	color: #6cf0de;
}
</style>
